<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="9"
      >
            <v-card>
                <v-card-title>
                    SCHOOL ADMIN LOGIN
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"
                    >
                        <v-row>
                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.email"
                                :counter="50"
                                label="E-mail Address"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.password"
                                :counter="15"
                                label="Password"
                                type="password"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="12">
                                <v-btn
                                color="success"
                                class="mr-4"
                                @click="post"
                                >
                                Signin
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
      </v-col>
      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="3"
      >
        <guest-menu-card v-if="isLoggedIn === false && isTokenAccess === false"/>
        <token-user-menu-card v-if="isLoggedIn === false && isTokenAccess === true"/>
        <auth-menu-card v-if="isLoggedIn === true"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import GuestMenuCard from '../../components/GuestMenuCard.vue'
import AuthMenuCard from '../../components/AuthMenuCard.vue';
import TokenUserMenuCard from '../../components/TokenUserMenuCard.vue';
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';

  export default {
    name: 'Signin',
    components: {
        AuthMenuCard, GuestMenuCard, TokenUserMenuCard,
        HeaderVue,
    },
    mounted() {
       if (this.authToken) {
           this.$router.push('dashboard')
       }
    },
    computed: {
        ...mapState({
            isLoggedIn: (state) => state.isLoggedIn,
            isTokenAccess: (state) => state.isTokenAccess,
        })
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        record:{
            email:null,
            password:null,
        },
    }),

    methods: {
        post () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/signin', this.record,
            {
                headers: 
                    {
                        'Device-Referrer-Id': process.env.VUE_APP_DEVICEID
                    }
            })
            .then((response) => {
                sessionStorage.authToken =  response.data.token
                sessionStorage.loggedUser =   JSON.stringify(response.data.data)
                localStorage.school =  JSON.stringify(response.data.data.school)
                sessionStorage.utpc =  'Teacher'
                this.$store.commit("isLoggedIn", true);
                sessionStorage.removeItem('accessToken')
                this.$router.push('dashboard')
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Server Downtime, Please contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    },
  }
</script>
